<template>
  <v-data-table
    :headers="$vuetify.breakpoint.xs ? headersXs : headers"
    :items="items"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    class="elevation-1 align-self-start col-12 pa-0"
    item-key="index"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Productos</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>

        <v-dialog v-model="dialog" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ item.Detalle }}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 300px;">
              <v-list two-line class="px-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Codigo }}</v-list-item-title>
                    <v-list-item-subtitle>Código</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Stock }}</v-list-item-title>
                    <v-list-item-subtitle>Stock</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.Division1 != 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Division1 }}</v-list-item-title>
                    <v-list-item-subtitle>División 1</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.Division2 != 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Division2 }}</v-list-item-title>
                    <v-list-item-subtitle>División 2</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.Division3 != 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Division3 }}</v-list-item-title>
                    <v-list-item-subtitle>División 3</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.Division4 != 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Division4 }}</v-list-item-title>
                    <v-list-item-subtitle>División 4</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="item.Division5 != 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Division5 }}</v-list-item-title>
                    <v-list-item-subtitle>División 5</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>


                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.Actualizacion }}</v-list-item-title>
                    <v-list-item-subtitle>Actualización</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeItem">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrecios" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ item.Detalle }}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 300px;">
              <v-list two-line subheader>
                <v-subheader>Precios</v-subheader>

                <v-list-item v-for="(item, index) in listaPrecios" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>${{ item.precio }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.lista }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closePrecios">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.Precio="{ item }">
      <v-btn
        v-if="item.flagPrecios || item.flagPrecios == null"
        icon
        color="info"
        @click="openPrecios(item)"
        aria-label="Precios"
        title="Ver Precios"
        :loading="item.loadingPrecios"
      >
        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>

      <div v-else>
          $ {{ item.lista_precio_principal }}
      </div>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        color="info"
        class="ml-4"
        @click="openItem(item)"
        aria-label="Detalle"
        title="Detalle"
        :loading="item.loadingItem"
      >
        mdi-eye
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>

import _ from 'lodash'

export default {
  data: () => ({
    loading: false,
    dialog: false,
    dialogPrecios: false,
    search: '',
    headersXs: [
      { text: 'Detalle', value: 'Detalle', sortable: false },
      { text: 'Precio', value: 'Precio', sortable: false },
      { text: '', value: 'action', align: 'right', sortable: false },
    ],
    headers: [
      { text: 'ID', value: 'id', align: 'left', sortable: false },
      { text: 'Código', value: 'Codigo', sortable: false },
      { text: 'Detalle', value: 'Detalle', sortable: false },
      { text: 'Precio', value: 'Precio', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    filtro: {
      desde: null,
      hasta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 10,
    },
    item: {},
    listaPrecios: [],
    itemDefault: {
      id: null,
      Codigo: '',
      Detalle: '',
      UnidadesPorBulto: null,
      TipoCarga: '',
      Imagen: '', // no mostrar
      Estado: false,
      DetalleBulto: '',
      Stock: null,
      Actualizacion: '',
      Precio0: null,
      Precio1: null,
      Precio2: null,
      Precio3: null,
      Precio4: null,
      Precio5: null,
      Division1: '',
      Division2: '',
      Division3: '',
      Division4: '',
      Division5: '',
    },
  }),

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function (/*val*/) {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true
      this.items = []
      this.total = 0

      this.filtro.consulta = this.search
      this.filtro.pagina_solicitada = this.options.page
      this.filtro.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarArticulos', {
        filtro: this.filtro,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = response.data.filtros.totalRegistros
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    openItem (item) {
      item.loadingItem = true

      this.$soapClient.call('getArticulo', {
        id: item.id,
      })
      .then((response) => {
        this.item = response.data.resultado
        this.dialog = true
      })
      .catch(() => {
        this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
      })
      .then(() => {
        item.loadingItem = false
      })
    },

    closeItem () {
      this.dialog = false
      this.item = {...this.itemDefault}
    },

    listarPrecios (item){
      this.$soapClient.call('listarPrecios', {
        id: item.id,
      })
      .then((response) => {
        this.listaPrecios = response.data.resultado
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    openPrecios (item) {
      item.loadingPrecios = true

      this.$soapClient.call('getArticulo', {
        id: item.id,
      })
      .then((response) => {
        this.listarPrecios(item)
        this.item = response.data.resultado
        this.dialogPrecios = true
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        item.loadingPrecios = false
      })
    },

    closePrecios () {
      this.dialogPrecios = false
      this.item = {...this.itemDefault}
    },

  },
};
</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> .v-dialog--scrollable .v-subheader,
  >>> .v-dialog--scrollable .v-list-item {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
</style>
